<template>
    <div>
        <!-- PC -->
        <v-sheet
            v-if="!$vuetify.breakpoint.mobile & !loading"
            class="pa-4 rounded-15"
            style="position:relative;"
        >
            <!-- 제목 -->
            <p
                class="text-h5 font-weight-bold mb-2 pt-5 pl-4"
                style="line-height:28px;"
            >
                <span class="primary--text">계약서</span><br/>
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:28px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <!-- 본문 -->
            <v-sheet
                class="mb-8 rounded-10 pt-2 pb-4 px-4"
            >
                <!-- 계약서 상단 -->
                <p
                    class="mt-4 mb-4 text-subtitle-2 font-weight-bold"
                    style="line-height:20px; width:520px;"
                >
                    {{top}}
                </p>
                
                <!-- 계약서 조항 -->
                <div v-for="(item, index) in clause_list" :key="index">
                    <p
                        class="mt-10 mb-4 text-subtitle-1 font-weight-bold grey--text text--darken-1"
                        style="line-height:20px;"
                    >
                        제{{index+1}}조 ({{item.title}})
                    </p>
                    <p
                        style="white-space: pre-line;"
                        class="mx-2 font-weight-medium grey--text text--darken-1"
                    >
                        {{item.content}}
                    </p>
                </div>
            </v-sheet>
        </v-sheet>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile & !loading"
            id="capture"
            class="pa-2 rounded-15"
            style="position:relative; background:white;"
        >
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold mb-0 pt-5 pl-4"
                style="line-height:28px;"
            >
                <span class="primary--text">계약서</span><br/>
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:16px; right:16px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <!-- 본문 -->
            <v-sheet
                class="mb-8 rounded-10 pb-4 px-4 mt-12"
            >
                <!-- 계약서 상단 -->
                <p
                    class="mt-4 mb-2 text-subtitle-2 font-weight-bold"
                    style="line-height:20px;"
                >
                    {{top}}
                </p>
                
                <!-- 계약서 조항 -->
                <div v-for="(item, index) in clause_list" :key="index">
                    <p
                        class="mt-6 mb-2 text-body-1 font-weight-bold grey--text text--darken-2"
                        style="line-height:20px;"
                    >
                        제{{index+1}}조 ({{item.title}})
                    </p>
                    <p
                        style="white-space: pre-line;"
                        class="mx-1 text-body-2 font-weight-medium grey--text text--darken-2"
                    >
                        {{item.content}}
                    </p>
                </div>
            </v-sheet>

            <p
                class="text-caption text-center mb-6 grey--text text--darken-1"
            >
                본 계약의 효력은 작성하신 내용 및 관련 법률에<br/>
                따라 달라질 수 있습니다.
            </p>

            <v-sheet
                v-if="sign && status != '계약완료'"
                color="#f4f2f1"
                class="mx-2"
            >
                <v-btn
                    block
                    class="rounded-10 mb-3"
                    height="48"
                    large
                    color="#fe7f5e"
                    dark
                    depressed
                    @click="dialog.sign = true"
                >
                    서명하기
                </v-btn>
            </v-sheet>
            <v-sheet
                id="exclude1"
                color="#f4f2f1"
                class="mx-2"
            >
                <v-btn
                    block
                    class="rounded-10 mb-3"
                    height="48"
                    large
                    color="primary"
                    dark
                    depressed
                    @click="capture()"
                >
                    계약내용 파일로 저장하기
                </v-btn>
            </v-sheet>

            <v-sheet
                v-if="status == '계약완료'"
                id="exclude2"
                color="#f4f2f1"
                class="mx-2"
            >
                <v-btn
                    block
                    class="rounded-10 mb-3"
                    height="48"
                    large
                    color="primary"
                    dark
                    depressed
                    @click="dialog.finish2 = true"
                >
                    SNS에 계약 체결 사실 공유하기
                </v-btn>
            </v-sheet>

            <p
                class="mt-4 text-center text-caption grey--text text--darken-1 mx-4"
            >
                *SNS에는 애칭으로만 공개되며, 실명 및 생년월일은 공개되지 않습니다.
            </p>
        </div>

        <!-- 계약서 완료 -->
        <v-dialog
            v-model="dialog.sign"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet
                class="pa-2"
            >
                <v-sheet
                    outlined
                    class="pa-2 rounded-xl"
                >
                    <v-sheet
                        color="grey lighten-4"
                        height="320"
                        class="rounded-xl pa-2 d-flex"
                        style="position:relative;"
                    >
                        <vue-drawing-canvas
                            class="mx-auto rounded-xl"
                            width="292"
                            height="304"
                            background-color="transparent"
                            ref="VueCanvasDrawing"
                        />
                        <p
                            style="padding-top:140px; position:absolute; top:0px; left:50%; transform:translateX(-50%);"
                            class="text-h6 font-weight-bold grey--text text--lighten-1 text-center"
                        >
                            서명해주세요
                        </p>
                    </v-sheet>
                </v-sheet>

                <!-- 버튼 -->
                <div
                    class="d-flex justify-center mt-4 pb-2"
                >
                    <v-btn
                        class="rounded-10 text-body-2"
                        depressed
                        dark
                        color="primary"
                        @click="dialog.switch = true; dialog.sign = false"
                    >
                        서명 완료하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 계약서 완료 -->
        <v-dialog
            v-model="dialog.switch"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                <v-img
                    class="mx-auto mb-2"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>
            </v-sheet>
            <v-sheet
                class="pt-8 pb-6"
            >
                <p
                    class="mx-10 font-weight-medium mb-0 text-center"
                >
                    <span class="primary--text">{{sender}}</span>님과 <span class="primary--text">{{receiver}}</span>님과의<br/>
                    연인계약을 공개하시겠습니까?
                </p>
                <div class="d-flex justify-center">
                    <v-switch v-model="reveal" label="공개"></v-switch>
                </div>
                
                <p
                    class="mt-0 mb-2 text-center text-caption grey--text text--darken-1 mx-4"
                >
                    *스윗딜에는 애칭으로만 공개되며,<br/>
                    실명 및 생년월일은 공개되지 않습니다.
                </p>

                <!-- 버튼 -->
                <div
                    class="d-flex justify-center"
                >
                    <v-btn
                        class="mt-4 rounded-10 text-body-2"
                        depressed
                        dark
                        color="primary"
                        @click="finished()"
                    >
                        계약 완료하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 계약서 완료 -->
        <v-dialog
            v-model="dialog.finish"
            width="440"
            content-class="rounded-xl"
        >
            <div
                id="capture2"
            >
                <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                    <v-img
                        class="mx-auto mb-2"
                        width="80"
                        src="@/assets/logo/default.png"
                    ></v-img>
                    <p
                        class="grey--text text-center text-caption mb-1"
                    >
                        sweetdeal<br/>
                        {{new Date().toLocaleDateString()}}
                    </p>
                </v-sheet>
                <v-sheet
                    class="pt-8 pb-6"
                >
                    <p
                        class="mx-10 font-weight-medium mb-6"
                    >
                        <span class="primary--text">{{sender}}</span>님과 <span class="primary--text">{{receiver}}</span>님이<br/>
                        <span class="primary--text">{{new Date().toLocaleDateString()}}</span> 스윗딜을 통해<br/>
                        연인계약을 체결했어요!
                    </p>

                    <!-- 이미지 저장 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex1"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            @click="capture2()"
                        >
                            SNS 공유하기
                        </v-btn>
                    </div>

                    <!-- 계약 작성자한테 알리기 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex2"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="200"
                            dark
                            color="primary"
                            @click="sendToSender()"
                        >
                            계약 작성자한테 알리기
                        </v-btn>
                    </div>
                </v-sheet>
            </div>
        </v-dialog>

        <!-- 체결 -> 카드확인 -->
        <v-dialog
            v-model="dialog.finish2"
            width="440"
            content-class="rounded-xl"
        >
            <div
                id="capture3"
            >
                <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                    <v-img
                        class="mx-auto mb-2"
                        width="80"
                        src="@/assets/logo/default.png"
                    ></v-img>
                    <p
                        class="grey--text text-center text-caption mb-1"
                    >
                        sweetdeal<br/>
                        {{new Date().toLocaleDateString()}}
                    </p>
                </v-sheet>
                <v-sheet
                    class="pt-8 pb-6"
                >
                    <p
                        class="mx-10 font-weight-medium mb-6"
                    >
                        <span class="primary--text">{{sender}}</span>님과 <span class="primary--text">{{receiver}}</span>님이<br/>
                        <span class="primary--text">{{new Date().toLocaleDateString()}}</span> 스윗딜을 통해<br/>
                        연인계약을 체결했어요!
                    </p>

                    <!-- 이미지 저장 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex3"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="220"
                            dark
                            color="primary"
                            @click="capture3()"
                        >
                            계약체결카드 캡쳐하기
                        </v-btn>
                    </div>

                    <!-- SNS에 계약체결 사실 공유하기 -->
                    <div
                        class="d-flex justify-center"
                    >
                        <v-btn
                            id="ex4"
                            class="mt-4 rounded-10 text-body-2"
                            depressed
                            width="220"
                            dark
                            color="primary"
                            @click="openInstagram()"
                        >
                            SNS에 계약체결 사실 공유하기
                        </v-btn>
                    </div>
                </v-sheet>
            </div>
        </v-dialog>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="60"
        ></v-progress-circular>
    </div>
</template>
<script>
import VueDrawingCanvas from "vue-drawing-canvas"
import html2canvas from 'html2canvas'

export default {
    components: {
        VueDrawingCanvas,
        html2canvas
    },

    props: ["id", "sign"],

    data: () => ({
        sender: "",
        receiver: "",
        top: "",
        reveal: 0,
        status: "",
        clause_list: [],

        dialog: {
            sign: false,
            switch: false,
            finish: false,
            finish2: false,
        },

        loading: true
    }),

    mounted(){
        // 계약서 정보 가져오기
        this.load()
    },
    
    methods: {
        // 계약서 정보 가져오기
        load(){
            this.$http.post("/api/contract/select/specific/id", {
                params: {
                    id: this.id
                }
            }).then((res) => {
                // console.log(res)
                // 발송인, 수신인, 계약서 상단
                this.sender = res.data[0].sender
                this.receiver = res.data[0].receiver
                this.top = res.data[0].top
                this.reveal = res.data[0].reveal
                this.status = res.data[0].status

                // 조항 가져오기
                this.$http.post("/api/contract/select/specific/clause_list", {
                    params: {
                        contract_id: res.data[0].id
                    }
                }).then((res) => {
                    this.clause_list = res.data
                    this.loading = false
                })
            })
        },

        // 인스타그램 앱 열기
        openInstagram() {
            window.open('https://www.instagram.com/', '_self');
        },

        // 계약 완료
        finished(){
            this.$http.post("/api/contract/update/status", {
                params: {
                    id: this.id,
                    reveal: this.reveal
                }
            }).then((res) => {
                this.dialog.finish = true
            })
        },

        // 캡처
        capture(){
            this.$globalSnackbar.show("설정에서 사진 앨범 권한을 허용해주세요")
            return

            const captureElement = document.getElementById('capture')
            const excludeIds = ['exclude1', 'exclude2'] // 제외할 요소의 ID 목록

            // 제외할 요소 숨기기
            excludeIds.forEach(id => {
                const element = document.getElementById(id)
                if (element) {
                    element.style.display = 'none'
                }
            })

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = "스윗딜 계약서 사본"
                a.click()

                // 이미지와 링크 요소 제거
                document.body.removeChild(img)
                a.remove()

                // 제외한 요소 다시 표시
                excludeIds.forEach(id => {
                    const element = document.getElementById(id)
                    if (element) {
                        element.style.display = ''
                    }
                })
            })
        },

        // 캡처 (서명후)
        capture2(){
            this.$globalSnackbar.show("설정에서 사진 앨범 권한을 허용해주세요")
            return

            const captureElement = document.getElementById('capture2')
            const excludeIds2 = ['ex1', 'ex2'] // 제외할 요소의 ID 목록

            // 제외할 요소 숨기기
            excludeIds2.forEach(id => {
                const element = document.getElementById(id)
                if (element) {
                    element.style.display = 'none'
                }
            })

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = "스윗딜 계약서 체결"
                a.click()

                // 이미지와 링크 요소 제거
                document.body.removeChild(img)
                a.remove()

                // 제외한 요소 다시 표시
                excludeIds2.forEach(id => {
                    const element = document.getElementById(id)
                    if (element) {
                        element.style.display = ''
                    }
                })
            })
        },

        // 캡처 (SNS 공유)
        capture3(){
            this.$globalSnackbar.show("설정에서 사진 앨범 권한을 허용해주세요")
            return
            
            const captureElement = document.getElementById('capture3')
            const excludeIds3 = ['ex3', 'ex4'] // 제외할 요소의 ID 목록

            // 제외할 요소 숨기기
            excludeIds3.forEach(id => {
                const element = document.getElementById(id)
                if (element) {
                    element.style.display = 'none'
                }
            })

            html2canvas(
                captureElement,
                {
                    scale: 2,
                    useCORS: true,
                }
            )
            .then(canvas => {
                const dataUrl = canvas.toDataURL('image/png')
                const img = document.createElement('img')
                img.src = dataUrl
                document.body.appendChild(img)
                const a = document.createElement('a')
                a.href = dataUrl
                a.download = "스윗딜 계약서 체결"
                a.click()

                // 이미지와 링크 요소 제거
                document.body.removeChild(img)
                a.remove()

                // 제외한 요소 다시 표시
                excludeIds3.forEach(id => {
                    const element = document.getElementById(id)
                    if (element) {
                        element.style.display = ''
                    }
                })
            })
        },

        // 계약서 입력 & 카카오톡 공유
        sendToSender() {
            // 카카오톡 공유
            Kakao.Share.sendDefault({
                objectType: "feed",

                content: {
                    title: '스윗딜 연인계약서에 서명했어요~!',
                    description: this.sender + "님과 " + this.receiver + "님의 계약",
                    imageUrl: 'https://romainex.com/upload/logo.png',
                    link: {
                        mobileWebUrl: 'https://romainex.com',
                        webUrl: 'https://romainex.com',
                    }
                },

                buttons: [
                    {
                        title: '웹으로 보기',
                        link: {
                            mobileWebUrl: 'https://romainex.com',
                            webUrl: 'https://romainex.com',
                        },
                    },
                    {
                        title: '앱으로 보기',
                        link: {
                            androidExecutionParams: 'param1=value1&param2=value2', // 안드로이드 딥 링크 파라미터
                            iosExecutionParams: 'param1=value1&param2=value2', // iOS 딥 링크 파라미터
                        },
                    },
                ],

                serverCallbackArgs: {
                    key: 'value', // 사용자 정의 파라미터 설정
                },
            })

            // 마이페이지 대시보드로 이동 (5초 딜레이 후)
            setTimeout(() => {
                this.loading = false
                this.$router.push('/mypage/dashboard')
            }, 5000)
        }
    }
}
</script>